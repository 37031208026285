import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import ReactWOW from "react-wow";

const HowWorks = () => {
  return (
    <div className="dg__work__area how__work">
      <div className="container" id="howworks">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <ReactWOW duration="2s" animation="fadeInLeft" scroll={true}>
              <div className="thumb">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/chart.svg"}
                  alt="growth_illustration"
                  width="550px"
                />
              </div>
            </ReactWOW>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--40  bull-container">
            <ReactWOW duration="2s" animation="fadeIn" scroll={true}>
              <div className="content">
                <h2>WHY INVEST THROUGH CAP-INDEX?</h2>
                <h6>
                  Cap-index is aimed at providing top-notch services in terms of
                  investment strategies and recommendations.
                </h6>
                <p>
                  We offer advisory and broking services for new and experienced
                  investors alike. We intend to encourage people to augment
                  their wealth through saving and investing habits. Through our
                  platforms, even a new investor with scarce knowledge about the
                  market can prosper and achieve their goals.
                </p>
                <HashLink
                  className="slide__btn dg__btn"
                  to={process.env.PUBLIC_URL + "/contact/#contactdet"}
                >
                  JOIN US NOW
                </HashLink>
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWorks;
