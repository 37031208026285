import React from "react";
// import { Link } from "react-router-dom";
import ReactWOW from "react-wow";
import { Link } from "react-scroll";

const SecureTransaction = () => {
  return (
    <div className="dg__secure__transaction" id="whatpage">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <ReactWOW duration="2s" animation="fadeIn" scroll={true}>
              <div className="dg__secure__inner">
                <h2>What we do at Cap-Index</h2>
                <p>
                  We at Cap-index aim to empower business houses through our
                  services, back them with practical and functional advisory,
                  and create platforms for their financial goals.
                </p>
                <p>
                  Good decision making is pivotal for the success of any
                  venture. The business ideas you implement based on your
                  decisions could pave the way to the rise or fall of your
                  empire. At Cap-index, we have a team of professional, skillful
                  experts who can help you with strategies for the development
                  of your business and mitigation of risks. Our team combines
                  their experiences and skills to provide you with the best
                  services.
                </p>
                <Link
                  className="secure__btn dg__btn btn--trans"
                  // activeClass="current"
                  to="service"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  Our Services
                </Link>
              </div>
            </ReactWOW>
          </div>
          <div className="col-lg-5">
            <ReactWOW duration="2s" animation="fadeInRight" scroll={true}>
              <div className="dg__secure__thumb">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/about/analytics_dark.svg"
                  }
                  alt="secure images"
                  width="595px"
                  height="609px"
                />
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecureTransaction;
