import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import HomeTwo from "./pages/HomeTwo";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ServiceDetails from "./pages/ServiceDetails";
import { AnimatePresence } from "framer-motion";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  const location = useLocation();
  return (
    <ScrollToTop>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={HomeTwo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/service"}`}
            component={ServiceDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
            component={PrivacyPolicy}
          />
        </Switch>
      </AnimatePresence>
    </ScrollToTop>
  );
}

export default App;
