import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import SoftwareDownloadTwo from "../components/software-downloads/SoftwareDownloadTwo";
import BrandLogoTwo from "../containers/brand-logos/BrandLogoTwo";
import CounterUp from "../containers/counter-ups/CounterUp";
import ServiceArea from "../components/live-chart/LiveChartTwo";
import HowWorks from "../components/how-works/HowWorks";
import HeroSliderTwo from "../containers/hero-sliders/HeroSliderTwo";
import LayoutTwo from "../layouts/LayoutTwo";
import SecureTransaction from "../components/secure-transactions/SecureTransaction";
import { motion } from "framer-motion";

const HomeTwo = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Cap-Index | Home Page</title>
        <meta
          name="description"
          content="Cap-Index: Stock Broker. Cap-Index is one of the best stock brokers in Kerala. We provide services such as consultancy, portfolio management, SIP and many more."
        />
        <meta
          name="keywords"
          content="cap index,cap-index, calicut,stock broker, angel broking share price,best trading platform,cap-index,kerala,investment,portfolio management, share market,best broker for trading in india,best broker for trading"
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="http://cap-index.com/" />
      </MetaTags>
      <motion.div exit={{ opacity: 0 }}>
        <LayoutTwo theme="dark">
          {/* hero slider */}
          <HeroSliderTwo />

          <SecureTransaction />

          {/* <BarHorizontal /> */}
          {/* how works */}
          <HowWorks />
          {/* live chart */}
          <ServiceArea />
          {/* work process */}
          {/* <WorkProcess /> */}
          {/* counter up */}
          <CounterUp backgroundImage="/images/bg/bg4.jpg" />
          {/* <CounterUp backgroundImage="/images/bg/4.jpg" /> */}
          {/* currency calculation */}
          {/* <CurrencyCalculationTwo /> */}
          {/* software download */}
          {/* <BrandLogoTwo /> */}

          <SoftwareDownloadTwo />
          {/* blog grid */}
          {/* <BlogGridTwo />  */}
        </LayoutTwo>
      </motion.div>
    </Fragment>
  );
};

export default HomeTwo;
