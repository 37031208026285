import React from "react";
import { HashLink } from "react-router-hash-link";

const Newsletter = () => {
  return (
    <div className="newsletter__area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="newsletter__container">
              <div className="newsletter__inner">
                <h2>Meet The Team At Cap-Index</h2>
                <p>
                  Get to know the people who keeps the wheels turing at
                  Cap-index{" "}
                </p>
                <div className="mt-4">
                  <HashLink className="team-btn" to="/about/#team">
                    Go
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
