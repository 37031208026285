import PropTypes from "prop-types";
import React from "react";
import { FaPhone } from "react-icons/fa";
import { HiMail } from "react-icons/hi";

const TeamSingle = ({ data }) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
      <div className="team">
        <div className="thumb">
          <img src={process.env.PUBLIC_URL + data.image} alt="team images" />
        </div>
        <div className="team__content">
          <h4>
            <a
              href="#"
              onClick="return false;"
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.name}
            </a>
          </h4>
          <p>{data.position}</p>
          <ul className="team__share d-flex justify-content-center">
            <li>
              <a
                href={"tel:" + data.phoneNumber}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaPhone />
              </a>
            </li>
            <li>
              <a
                href={"mailto:" + data.eMail}
                target="_blank"
                rel="noopener noreferrer"
              >
                <HiMail />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

TeamSingle.propTypes = {
  data: PropTypes.object,
};

export default TeamSingle;
