import React, { Fragment } from "react";
import { MetaTags } from "react-meta-tags";
import { motion } from "framer-motion";
import LayoutCustom from "../layouts/LayoutCustom";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import breadcrumbData from "../data/breadcrumb/breadcrumb-data.json";
import PrivacyPolicyContent from "../components/privacy-policy-content/PrivacyPolicyContent";

const PrivacyPolicy = () => {
  return (
    <>
      <Fragment>
        <MetaTags>
          <title>Cap-Index | Privacy Policy</title>
          <meta name="description" content="Privacy Policy Cap-Index." />
        </MetaTags>
        <motion.div exit={{ opacity: 0 }}>
          <LayoutCustom theme="white">
            {/* breadcrumb */}
            <Breadcrumb data={breadcrumbData[3]} />
            <PrivacyPolicyContent />
          </LayoutCustom>
        </motion.div>
      </Fragment>
    </>
  );
};

export default PrivacyPolicy;
