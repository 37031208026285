import React from "react";
import SectionTitleFour from "../ui/section-titles/SectionTitleFour";
import { Tab, Nav } from "react-bootstrap";

const AboutContent = () => {
  return (
    <div className="about__dgtaka about--2 section-padding--xl" id="mission">
      <div className="container">
        <div className="tab-buttons">
          <Tab.Container defaultActiveKey="mission">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="mission">Mission</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="vision">Vision</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="mission">
                <div className="row align-items-center pt--50">
                  <div className="col-lg-7 col-12 col-sm-12 col-md-12">
                    <div className="dg__secure__inner">
                      <SectionTitleFour title="Our Mission" />
                      <ul className="inner-list">
                        <li>
                          To provide stockbroking at
                          affordable rates.
                        </li>
                        <li>
                          To work faithfully and ensure 100% transparency in the
                          services that we provide.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-5 col-12 col-sm-12 col-md-12 sm__mt--40 md__mt--40">
                    <div className="dg__secure__thumb">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/about/mission.png"
                        }
                        alt="secure images"
                      />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="vision">
                <div className="row align-items-center  pt--50">
                  <div className="col-lg-5 col-12 col-sm-12 col-md-12 sm__mt--40 md__mt--40">
                    <div className="dg__secure__thumb">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/about/vision.png"
                        }
                        alt="secure images"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-12 col-sm-12 col-md-12">
                    <div className="dg__secure__inner">
                      <SectionTitleFour title="Our Vision" />

                      <p>
                        Capindex currently thrives as an LLP. We aim to convert
                        it into a well-functioning public company within 5
                        years. Subsequently, our target is to issue an IPO
                        within 10 years from now. Functionally, we intend to
                        ensure more than 90% year- on- year returns for all the
                        clients under us, through our different service
                        platforms.
                      </p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        {/*  */}
      </div>
    </div>
    // <div className="about__dgtaka about--2 section-padding--xl">
    //   <div className="container">
    //     <div className="row align-items-center">
    //       <div className="col-lg-7 col-12 col-sm-12 col-md-12">
    //         <div className="dg__secure__inner">
    //           {/* section title */}
    //           <SectionTitleFour title="About HOWARD & its Future" />

    //           <p>
    //             There are many variations of passages of Lorem Ipsum available,
    //             but the majority have suffered alteration in some form, by
    //             injected humour, or randomised words which don't look even
    //             slightly believable.
    //           </p>
    //           <p>
    //             There are many variations of passages of Lorem Ipsum available,
    //             but the majority have suffered alteration in some form, by
    //             injected humour, or randomised words which don't look even
    //             slightly believable.
    //           </p>
    //         </div>
    //       </div>
    //       <div className="col-lg-5 col-12 col-sm-12 col-md-12 sm__mt--40 md__mt--40">
    //         <div className="dg__secure__thumb">
    //           <img
    //             src={process.env.PUBLIC_URL + "/images/about/6.png"}
    //             alt="secure images"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default AboutContent;
