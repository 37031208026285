import React from "react";
import { Accordion, Card } from "react-bootstrap";

const AccordionContainer = () => {
  return (
    <Accordion>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          What is Capital Market?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            The capital market is where long term securities like stocks, bonds,
            debentures, etc are traded. It is the arena where long-term
            financing is facilitated and capital requirements are met. Out of
            the various instruments that are traded in the capital market,
            Stocks and indices are what our firm deals with. Our services range
            throughout the different aspects of stock trading.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1">
          What is Stock Market?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            The stock market is where shares of public companies are bought and
            sold. Here, you can make money by investing in stocks or by trading
            in them. Investing in the stock market is a great way of creating
            wealth. It is an efficient way of enhancing your saving habits. If
            you are willing to invest in the stock market, you can assuredly
            expect substantial returns in the long run. On the other hand,
            trading in stocks also has its perks. The difference is that it is
            focused on generating profits in short or specific periods of time.
            Although the presence of risk is inevitable, trading and investing
            in the stock market are sure ways to make good money. However, it is
            not possible for an individual to buy or sell shares on his/her own.
            This is where stock broking comes into play.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2">
          Why Do You Need A Stock Broker?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            It is not possible for a person to directly trade in stocks in the
            market. It can only be done through stock brokers. They act as
            middlemen who carry out the trade on behalf of the investors.{" "}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3">
          Who Can Benefit From Our Services?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <ul>
              <li>
                Those who are interested in creating wealth through investing in
                the stock market and trading in stocks
              </li>
              <li>
                Those who are looking to improve their trade and discover new
                developmental ideas and strategies for their business, and
                thereby generate more profits.
              </li>
              <li>
                Those who are willing to make use of our services to generate
                consistent returns. We create platforms for those willing to
                trade in equity, options, futures and commodities.
              </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="4">
          What Are The Myths About Investing?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            The stock market is a realm of opportunities and promises, but also
            of risks and chances. Stepping into it with false mindsets and
            unrealistic expectations is impractical. It can steer you away from
            the real opportunities it offers.
            <ul>
              <li>
                It is a known fact that the main aim of investing is to earn
                profits. However, new investors often enter into the world of
                the stock market carrying a misconception of making high returns
                overnight. Your focus should not be to make 100% profits within
                a month or two. Rather, you should just aim at consistent
                returns regularly. This way you can make considerable
                percentages of profits yearly.
              </li>
              <li>
                The prospect of creating wealth when approached with an element
                of emotion, often makes investors overlook an important fact;
                which is that your returns should be assessed based on the
                capital that you have invested. Rather than focusing on your
                returns in absolute figures, your profits ought to be evaluated
                based on what percentage they are of your capital. Fixating on
                absolute figures can blur your vision of what success really
                means in the stock market.
              </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default AccordionContainer;
