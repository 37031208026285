import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { Link as ScrollLink } from "react-scroll";
import { HashLink } from "react-router-hash-link";

const Navigation = () => {
  const [currentUrl] = useState(window.location.pathname);
  const [homeFlag, setFlag] = useState(true);

  useEffect(() => {
    if (currentUrl === "/") {
      setFlag(true);
    } else {
      setFlag(false);
    }
  }, [currentUrl]);

  return (
    <nav className="mainmenu__nav">
      <ul className="mainmenu">
        <li className="drop">
          {homeFlag ? (
            <ScrollLink
              activeClass="current"
              to="hero"
              offset={-10}
              spy={true}
              smooth={true}
              duration={500}
            >
              Home
            </ScrollLink>
          ) : (
            <HashLink to={process.env.PUBLIC_URL + "/"}>Home</HashLink>
          )}
        </li>
        <li className="drop">
          {homeFlag ? (
            <ScrollLink
              activeClass="current"
              to="service"
              spy={true}
              offset={-50}
              smooth={true}
              duration={500}
            >
              Service
            </ScrollLink>
          ) : (
            <HashLink to={process.env.PUBLIC_URL + "/#service"}>
              Services
            </HashLink>
          )}
          <ul className="dropdown">
            <li>
              <HashLink to={process.env.PUBLIC_URL + "/service/"}>
                Our Services
              </HashLink>
            </li>
            <li>
              <HashLink to={process.env.PUBLIC_URL + "/service/#faq"}>
                FAQs
              </HashLink>
            </li>
          </ul>
        </li>
        <li className="drop">
          <Link to={process.env.PUBLIC_URL + "/about"}>About</Link>
          <ul className="dropdown">
            <li>
              <HashLink to={process.env.PUBLIC_URL + "/about/#team"}>
                Team
              </HashLink>
            </li>
            <li>
              <HashLink to={process.env.PUBLIC_URL + "/about/#mission"}>
                Mission &amp; Vision
              </HashLink>
            </li>
          </ul>
        </li>
        <li className="drop">
          <Link to={process.env.PUBLIC_URL + "/contact/"}>Contact</Link>
          <ul className="dropdown">
            <li>
              <HashLink to={process.env.PUBLIC_URL + "/contact/#maps"}>
                Locate Us
              </HashLink>
            </li>
            <li>
              <HashLink to={process.env.PUBLIC_URL + "/contact/#contactdet"}>
                Contact Details
              </HashLink>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
