import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutCustom from "../layouts/LayoutCustom";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import AboutContent from "../components/about-contents/AboutContent";
import AboutContentTwo from "../components/about-contents/AboutContentTwo";
import VideoPopup from "../components/video-popups/VideoPopup";
import breadcrumbData from "../data/breadcrumb/breadcrumb-data.json";
import Brief from "../components/about-contents/Brief";
import { motion } from "framer-motion";

const About = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Cap-Index | About Us</title>
        <meta name="description" content="About Cap-Index." />
      </MetaTags>
      <motion.div exit={{ opacity: 0 }}>
        <LayoutCustom theme="white">
          {/* breadcrumb */}
          <Breadcrumb data={breadcrumbData[0]} />
          {/* about content */}
          <Brief />
          <AboutContent />
          {/* video popup */}
          <VideoPopup />
          {/* about content two */}
          <AboutContentTwo />
        </LayoutCustom>
      </motion.div>
    </Fragment>
  );
};

export default About;
