import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutCustom from "../layouts/LayoutCustom";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import GoogleMap from "../components/google-maps/GoogleMap";
import breadcrumbData from "../data/breadcrumb/breadcrumb-data.json";
import { MdLocationOn } from "react-icons/md";
import { HiMail } from "react-icons/hi";
import { AiTwotonePhone } from "react-icons/ai";
import { IoMdShare } from "react-icons/io";
import { RiInstagramLine } from "react-icons/ri";
import { AiFillChrome } from "react-icons/ai";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Cap-Index | Contact</title>
        <meta name="description" content="Contact page of Cap-Index." />
      </MetaTags>
      <motion.div exit={{ opacity: 0 }}>
        <LayoutCustom theme="white">
          {/* breadcrumb */}
          <Breadcrumb data={breadcrumbData[2]} />
          {/* contact page content */}
          <section className="conact__area pt--140">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="contact__title padding--hor">
                    <img
                      src={process.env.PUBLIC_URL + "/images/about/contact.svg"}
                      alt="conatct images"
                      width="500px"
                    />
                    <h2>Drop a Mail or Phone in!</h2>
                    <p>
                      We would love to hear from you. Drop a mail or ring us up
                      any time for any queries or questions. You can also
                      schedule an appointment at our office situated near
                      Calicut Beach. We look forward to meeting you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="contact-bg-color tickets--2 bg__ticker__ares"
              id="contactdet"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12 right_border">
                    <div className="ticket">
                      <div className="contact-icons">
                        <MdLocationOn />
                      </div>
                      <h6 className="text-center">Address</h6>
                      <a
                        href="https://goo.gl/maps/BCTw17YNiiKSMitX7"
                        target="_blank"
                      >
                        <p>
                          Cap-Index LLP
                          <br />
                          62/1155 The Hub
                          <br />
                          Cherooty Road, Near Gandhi Park
                          <br />
                          Kozhikode, 673001
                          <br /> Kerala, India
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12 right_border">
                    <div className="ticket">
                      <div className="contact-icons">
                        <HiMail />
                      </div>
                      <h6 className="text-center">Mail</h6>
                      <a href="mailto:connect@cap-index.com" target="_blank">
                        <p>connect@cap-index.com</p>
                      </a>
                      {/* <a href="mailto:hello@cap-index.com" target="_blank">
                        <p>hello@cap-index.com</p>
                      </a> */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12 right_border">
                    <div className="ticket">
                      <div className="contact-icons">
                        <AiTwotonePhone />
                      </div>
                      <h6 className="text-center">Phone</h6>
                      <a href="tel:+919074376017" target="_blank">
                        <p>+91 9074 376 017</p>
                      </a>
                      <a href="tel:+917907302020" target="_blank">
                        <p>+91 7907 302 020</p>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12 right_border">
                    <div className="ticket">
                      <div className="contact-icons">
                        <IoMdShare />
                      </div>
                      <h6 className="text-center">Socials</h6>
                      <a
                        href="https://www.instagram.com/cap_index/"
                        target="_blank"
                      >
                        <p>
                          <RiInstagramLine />
                          &nbsp;instagram.com/cap_index/
                        </p>
                      </a>
                      <a href="https://www.cap-index.com/" target="_blank">
                        <p>
                          <AiFillChrome />
                          &nbsp;cap-index.com
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container" id="maps">
              <div className="row align-items-center">
                <div className="col-lg-12 pt--100 pb--60">
                  <div className="google__map">
                    {/* contact map */}
                    <GoogleMap />
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-6 sm__mt--40 md__mt--40">
                <div className="dg__contact__wrapper">
                  <form className="contact-form">
                    <div className="single-contact-form">
                      <span>Full Name</span>
                      <input type="text" name="firstname" />
                    </div>
                    <div className="single-contact-form">
                      <span>Email</span>
                      <input type="email" name="email" />
                    </div>
                    <div className="single-contact-form">
                      <span>Subject</span>
                      <input type="text" name="subject" />
                    </div>
                    <div className="single-contact-form message">
                      <span>Message</span>
                      <textarea name="message" defaultValue={""} />
                    </div>
                    <div className="contact-btn">
                      <button type="submit">
                        Submit
                        <span className="ti-arrow-right" />
                      </button>
                    </div>
                  </form>
                </div>
              </div> */}
            </div>
          </section>
        </LayoutCustom>
      </motion.div>
    </Fragment>
  );
};

export default Contact;
