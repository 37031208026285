import React from "react";
// import { Link } from "react-router-dom";
import {
  FaWhatsapp,
  FaInstagram,
  FaMobileAlt,
  FaPhone,
  FaRegEnvelope,
  // FaUserAlt,
} from "react-icons/fa";

const MobileWidgets = () => {
  return (
    <div className="offcanvasWidgetArea">
      <div className="offCanvasContactWidget">
        <div className="headerContactInfo">
          <ul className="headerContactInfoList">
            {/* <li>
              <FaUserAlt />{" "}
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                Login / Register{" "}
              </Link>
            </li> */}
            <li>
              <FaPhone /> <a href="tel://+914953562777">+91 495 3562777</a>
            </li>
            <li>
              <FaMobileAlt /> <a href="tel://+919074376017">+91 9074376017</a>
            </li>
            <li>
              <FaRegEnvelope />{" "}
              <a href="mailto:connect@cap-index.com">connect@cap-index.com</a>
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      <div className="offCanvasWidgetSocial">
        <a
          href="https://wa.me/+919074376017"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp />
        </a>

        <a
          href="//instagram.com/cap_index/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
      </div>
      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;
