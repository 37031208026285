import PropTypes from "prop-types";
import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { MdPeople } from "react-icons/md";
import { BsGeoAlt } from "react-icons/bs";
import { RiAwardFill } from "react-icons/ri";
import { AiFillProject } from "react-icons/ai";
import ReactWOW from "react-wow";

const icons = [
  <BsGeoAlt className="counter-icons" />,
  <MdPeople className="counter-icons" />,
  <RiAwardFill className="counter-icons" />,
  <AiFillProject className="counter-icons" />,
];
const CounterUpSingle = ({ data }) => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };
  return (
    <div className="counterup__list">
      <div className="counter-icon-container">
        <ReactWOW duration="2`s" animation="rotateIn" scroll={true}>
          {icons[data.iconIndex]}
        </ReactWOW>
      </div>
      <p className="counter-active">
        <VisibilitySensor
          onChange={onVisibilityChange}
          offset={{ top: 10 }}
          delayedCall
        >
          <CountUp end={didViewCountUp ? data.number : 0} />
        </VisibilitySensor>
        {data.plus}
      </p>
      <h6>{data.title}</h6>
    </div>
  );
};

CounterUpSingle.propTypes = {
  data: PropTypes.object,
};

export default CounterUpSingle;
