import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import ParticleTwo from "../particles/ParticleTwo";

const Breadcrumb = ({ data }) => {
  return (
    <div
      className="ht__bradcaump__area"
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 35, 99, 0.6), rgba(0, 35, 99, 0.6)),url(${
          process.env.PUBLIC_URL + data.imgUrl
        })`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
      }}
    >
      <div className="bread-outer">
        <div className="ht__bradcaump__container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="bradcaump__inner text-center">
                  <h2 className="bradcaump-title">{data.title}</h2>
                  <nav className="bradcaump-inner">
                    <Link
                      className="breadcrumb-item"
                      id="breadcrumb-item"
                      to={process.env.PUBLIC_URL + "/"}
                    >
                      Home
                    </Link>
                    <span className="brd-separetor">/</span>
                    <span className="breadcrumb-item active">{data.title}</span>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* particle */}
      <ParticleTwo />
    </div>
  );
};

Breadcrumb.propTypes = {
  data: PropTypes.array,
};

export default Breadcrumb;
