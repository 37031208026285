import React from "react";
import SectionTitleFour from "../ui/section-titles/SectionTitleFour";

const Brief = () => {
  return (
    <div className="container brief-outer">
      <div className="row justify-content-center pt--100">
        <SectionTitleFour title="A Brief About Cap-Index" />
        <p className="brief_p text-center">
          Cap-index is a team of experienced experts who offer an array of
          dynamic services that covers stock broking. With
          us, you can discover your greater business potentials. We aim to
          empower business houses through our services, back them with practical
          and functional advisory, and create platforms for their financial
          goals.
        </p>
        <p className="brief_p text-center">
          At Cap-index, we have a team of professional, skillful experts who can
          help you with strategies for the development of your business and
          mitigation of risks. Our team combines their experiences and skills to
          provide you with the best services.
        </p>
      </div>
    </div>
  );
};

export default Brief;
