import React from "react";
import SectionTitleFour from "../ui/section-titles/SectionTitleFour";
import { GiMoneyStack } from "react-icons/gi";
import { AiOutlineStock } from "react-icons/ai";

const ServiceContentTwo = () => {
  return (
    <div className="dg__secure__transaction--2 secure--2 section-padding--xl">
      <div className="container">
        <SectionTitleFour title="OUR SERVICES" />
        <div className="row align-items-center  pt--30 pb--30" id="ourservices">
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--40">
            <div className="service-icon-box">
              <GiMoneyStack />
            </div>
          </div>
        </div>
        <div className="row align-items-center pt--30 pb--30" id="stockbroking">
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
            <h2 className="title__with__border">Stock broking</h2>
            <p>
              We provide stock broking facilities to any and all segments of the
              capital market. We assist investors in making the right investment
              choices for obtaining the best gains.
            </p>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--40">
            <div className="service-icon-box">
              <AiOutlineStock />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceContentTwo;
