import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { GiMoneyStack } from "react-icons/gi";
import { AiOutlineStock } from "react-icons/ai";
import { HashLink } from "react-router-hash-link";

const ServiceArea = () => {
  return (
    <div className="dg__live__chart bg__color--4 pb--140 pt--130" id="service">
      <div className="container">
        <div className="row alignment">
          <div className="col-lg-12">
            <div className="live__cart__wrapper">
              <div className="live__cart__header">
                <h2>Our Services</h2>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="service-outer">
                    <div className="service-content">
                  
                      <div className="service-meta">
                        <GiMoneyStack className="service-icons" />
                        <HashLink
                          className="svc-btn"
                          to={process.env.PUBLIC_URL + "/service/#ourservices"}
                        >
                          Explore more
                        </HashLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="service-outer">
                    <div className="service-content">
                      <h2>Stock Broking</h2>
                      <p>
                        We provide stock broking facilities to any and all
                        segments of the capital market. We assist investors in
                        making the right investment choices for obtaining the
                        best gains.
                      </p>
                      <div className="service-meta">
                        <AiOutlineStock className="service-icons" />
                        <HashLink
                          className="svc-btn"
                          to={process.env.PUBLIC_URL + "/service/#stockbroking"}
                        >
                          Explore more
                        </HashLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceArea;
