import React from "react";
import AccordionContainer from "../accordion/AccordionContainer";
import SectionTitleFour from "../ui/section-titles/SectionTitleFour";

const ServiceContentThree = () => {
  return (
    <section className="padding--hor">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12" id="faq">
            <SectionTitleFour title="FAQ" />
            <AccordionContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceContentThree;
