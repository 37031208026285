import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { FaWhatsapp, FaLinkedin, FaInstagram } from "react-icons/fa";
import { MdExpandLess } from "react-icons/md";
import { ImHeart } from "react-icons/im";

const Footer = ({ theme }) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <footer
      className={`footer-area ${theme === "white" ? "footer--2" : "footer--1"}`}
    >
      <div
        className={`dg__footer__container ${
          theme === "white" ? "bg--white" : "bg__color--2"
        }`}
      >
        <div className="container">
          <div className="row text-right">
            {/* Start Single Widget */}
            <div className="col-lg-12 md__mt--40 sm__mt--40">
              <div className="footer__widget resources">
                <h4>Contact</h4>
                <div className="footer__inner">
                  <ul>
                    <li>
                      <a href="mailto:connect@cap-index.com">
                        connect@cap-index.com
                      </a>
                    </li>
                    <li>
                      <a href="mailto:hr@cap-index.com">hr@cap-index.com</a>
                    </li>
                    <li>
                      <a
                        href="https://goo.gl/maps/4Rnw1MzizXCA2jcVA"
                        target="_blank"
                      >
                        <br /> Cap-Index LLP
                        <br />
                        <br /> 612/1155 The Hub, Cherooty Road
                        <br /> Near Gandhi park Kozhikode, 673001
                        <br /> KL, IN
                      </a>
                    </li>
                    <br />
                    <li>
                      <a href="tel:+91 7907302020">+91 7907 730 2020</a>
                    </li>
                    <li>
                      <a href="tel:+919074376017">+91 9074 376 017</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* End Single Widget */}
          </div>
        </div>
      </div>
      <div
        className={`copyright ${
          theme === "white" ? "bg__color--1" : "bg--black"
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="copyright__inner">
                <p>
                  Hosted With &nbsp;
                  <ImHeart className="heart" />
                  &nbsp; By Cap-Index |
                  &nbsp;<a href={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</a>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-12">
              <ul className="footer__right social__icon">
                <li>
                  <a
                    href="https://wa.me/+919074376017"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp />
                  </a>
                </li>
                {/* <li>
                  <a
                    href="//linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin />
                  </a>
                </li> */}
                <li>
                  <a
                    href="//instagram.com/cap_index/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <MdExpandLess />
      </button>
    </footer>
  );
};

export default Footer;
