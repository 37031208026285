import React from "react";
import SectionTitleSix from "../../components/ui/section-titles/SectionTitleSix";
import serviceFeatureData from "../../data/service-features/service-feature-one.json";
import ServiceFeatureSingle from "../../components/service-contents/ServiceFeatureSingle";

const ServiceFeature = () => {
  return (
    <div
      className="dg__service__area  padding--hor2"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bg/9.jpg"})`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* section title */}
            <SectionTitleSix
              title="What Makes Us Different"
              text="At Cap-Index, we strive to offer the best support and services to our clients. Here is what makes us different from our competitors"
            />
          </div>
        </div>
      </div>
      <div className="custom__service__width decrease--top">
        {serviceFeatureData &&
          serviceFeatureData.map((single, key) => {
            return <ServiceFeatureSingle data={single} key={key} />;
          })}
      </div>
    </div>
  );
};

export default ServiceFeature;
