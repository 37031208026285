import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutCustom from "../layouts/LayoutCustom";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import ServiceContentTwo from "../components/service-contents/ServiceContentTwo";
import ServiceContentThree from "../components/service-contents/ServiceContentThree";
import ServiceFeature from "../containers/service-contents/ServiceFeature";
import breadcrumbData from "../data/breadcrumb/breadcrumb-data.json";
import { motion } from "framer-motion";

const ServiceDetails = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Cap-Index | Services Offered</title>
        <meta name="description" content="Services Offered By Cap-Index." />
      </MetaTags>
      <motion.div exit={{ opacity: 0 }}>
        <LayoutCustom theme="white">
          {/* breadcrumb */}
          <Breadcrumb data={breadcrumbData[1]} />
          {/* service content */}
          <ServiceContentTwo />
          {/* service features */}
          <ServiceFeature />
          {/* service content */}
          <ServiceContentThree />
        </LayoutCustom>
      </motion.div>
    </Fragment>
  );
};

export default ServiceDetails;
