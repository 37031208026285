import React from "react";
import { HashLink } from "react-router-hash-link";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdEmail } from "react-icons/md";

const SoftwareDownloadTwo = () => {
  return (
    <section
      className="dg__software__area dg__software--2 section-padding--xl"
      style={{
        backgroundImage: `linear-gradient(
          180deg,
          rgba(0, 18, 50, 1) 0%,rgba(0, 18, 50, 0.9)20% ),url(${
            process.env.PUBLIC_URL + "/images/bg/meet2.jpg"
          })`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
      id="download"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="dg__software__inner">
              <h2>Let's talk over coffee!</h2>
              <p>
                Feel free to walk in to our office situated at Cherooty Road,
                Calicut, to talk business or even for a chat. You can also
                contact us via email. Leave a messge for us at{"  "}
                <b>
                  <a href="mailto:connect@cap-index.com">
                    connect@cap-index.com
                  </a>
                </b>
                {"  "} for any and all enquiries and concern and we'll be sure
                to write back.
              </p>
              <HashLink
                className="dwnld_btn"
                to={process.env.PUBLIC_URL + "/contact/#maps"}
              >
                <HiOutlineLocationMarker className="dwnld-icons" />
                &nbsp; Show On Maps
              </HashLink>
              <br />
              <a className="dwnld_btn" href="mailto:connect@cap-index.com">
                <MdEmail className="dwnld-icons" />
                &nbsp;&nbsp; Send Mail
              </a>
              {/* <ul className="avilable__device">
                <li>
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/4.png"}
                      alt="images"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/5.png"}
                      alt="images"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/6.png"}
                      alt="images"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/7.png"}
                      alt="images"
                    />
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SoftwareDownloadTwo;
