import React from "react";

const GoogleMap = () => {
  return (
    <div className="contact-map-area" data-aos="fade-up">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d978.281411182243!2d75.77262322919243!3d11.252166514826058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba659ddc85ab26f%3A0xd840d7a5492257!2sCap-Index!5e0!3m2!1sen!2sin!4v1624173590369!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
