import PropTypes from "prop-types";
import React from "react";
// import { Link } from "react-router-dom";
import ParticleTwo from "../particles/ParticleTwo";
import { Link as ScrollLink } from "react-scroll";

const HeroSliderTwoSingle = ({ data }) => {
  return (
    <div
      className="slide d-flex align__center poss--relative"
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 35, 99, 0.3), rgba(0, 35, 99, 0.5)90%,rgba(26, 24, 37, 1)99.99%),url(${
          process.env.PUBLIC_URL + data.backgroundImage
        })`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xl-7 col-md-12 col-sm-12 col-12 d-flex align-items-center">
            <div className="slide__inner">
              <h1>{data.title}</h1>
              <p>{data.text}</p>

              {/* <Link
                className="slide__btn dg__btn btn--white btn--theme"
                to={process.env.PUBLIC_URL + data.url}
              >
                Read More
              </Link> */}
              <ScrollLink
                className="slide__btn dg__btn btn--white btn--theme"
                activeClass="current"
                to="whatpage"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
              >
                Read More
              </ScrollLink>
            </div>
          </div>
        </div>
      </div>
      {/* particle */}
      <ParticleTwo />
    </div>
  );
};

HeroSliderTwoSingle.propTypes = {
  data: PropTypes.object,
};

export default HeroSliderTwoSingle;
