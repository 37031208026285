import React from "react";
// import SectionTitleFour from "../ui/section-titles/SectionTitleFour";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import ReactWOW from "react-wow";
import teamData from "../../data/teams/team-one.json";
import TeamSingle from "../../components/teams/TeamSingle.js";
import SectionTitleFive from "../ui/section-titles/SectionTitleFive";

const AboutContentTwo = () => {
  return (
    <div
      className="dg__service__area bg--white padding--hor team-outer"
      id="team"
    >
      <div className="container">
        <div className="row team-title justify-content-center">
          <SectionTitleFive title="Team Leaders" />
          <p>
            Capindex is led by a duo well- versed in the stock market and its
            dynamics.
          </p>
        </div>

        <ReactWOW duration="1s" animation="fadeIn" scroll={true}>
          <div className="container team-main pt--30 pb--30">
            <div className="row">
              <div className="col-md-5">
                <div className="member-pic mb-sm-35">
                  <img
                    src={process.env.PUBLIC_URL + "/images/team/hathim.jpg"}
                    alt="Hathim Mohammed"
                  />
                </div>
              </div>
              <div className="col-md-7 ml-auto">
                <div className="member-desc">
                  <h2>Hathim Mohammed</h2>
                  <h5>Director</h5>
                  <p>
                    Hathim Mohammed is equipped with skillful expertise in
                    futures, options, equity and commodity through his
                    experience of more than 20 years in the stock market
                  </p>

                  <div className="member-social-icons mt-25">
                    <a
                      href="https://www.facebook.com/mohammed.hathim"
                      target="_blank"
                    >
                      <FaFacebookSquare />
                    </a>
                    <a
                      href="https://www.instagram.com/hathimmohammed/"
                      target="_blank"
                    >
                      <FaInstagramSquare />
                    </a>
                    {/* <a href="#">
                      <FaLinkedin />
                    </a> */}
                  </div>
                  <div className="contact-info">
                    <p>
                      <strong>Phone</strong> +918089732372
                    </p>
                    <p>
                      <strong>Email</strong> Hathim@cap-index.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactWOW>

        <ReactWOW duration="1s" animation="fadeIn" scroll={true}>
          <div className="container team-main pt--30 pb--30">
            <div className="row">
              <div className="col-md-7 ml-auto">
                <div className="member-desc">
                  <h2>Thwayyb Mohiudheen</h2>
                  <h5> Research Analyst &amp; Managing Director</h5>
                  <p>
                    Thwayyb Mohiudheen, Son of Hathim Mohammed, started stock
                    broking in march 2020 and also has remarkable experience of
                    more than 5 years in equity, futures, options and hedging
                    strategies.
                  </p>

                  <div className="member-social-icons mt-25">
                    <a
                      href="https://www.facebook.com/thwayyb.hathim"
                      target="_blank"
                    >
                      <FaFacebookSquare />
                    </a>
                    <a
                      href="https://www.instagram.com/thwayyb_hathim/"
                      target="_blank"
                    >
                      <FaInstagramSquare />
                    </a>
                    {/* <a href="#">
                      <FaLinkedin />
                    </a> */}
                  </div>
                  <div className="contact-info">
                    <p>
                      <strong>Phone</strong> +917736109932
                    </p>
                    <p>
                      <strong>Email</strong> Thwayyb@cap-index.com
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="member-pic mb-sm-35">
                  <img
                    src={process.env.PUBLIC_URL + "/images/team/thwayib.jpg"}
                    alt="Thwayyb Mohiudheen"
                  />
                </div>
              </div>
            </div>
          </div>
        </ReactWOW>
        <div className="text-center">
          <h3 className="team-mmbrs pt--30 pb--10">Team Members</h3>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="team__wrapper">
            <div className="row">
              {teamData &&
                teamData.map((single, key) => {
                  return <TeamSingle data={single} key={key} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContentTwo;
