import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { AiOutlinePlayCircle } from "react-icons/ai";

const VideoPopup = () => {
  const [modalStatus, isOpen] = useState(false);
  return (
    <div
      className="dg__video__area"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bg/6.jpg"})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="dg__video__inner">
              <h2>Get to know us better</h2>
              <p>This is a short montage by Cap-Index</p>
              <div className="play__video">
                <ModalVideo
                  channel="youtube"
                  isOpen={modalStatus}
                  videoId="N4tW6JrjeG4"
                  onClose={() => isOpen(false)}
                />
                <button onClick={() => isOpen(true)} className="play__btn">
                  {/* <img
                    src={process.env.PUBLIC_URL + "/images/about/play.png"}
                    alt="play icon"
                  /> */}
                  <AiOutlinePlayCircle className="play-btn" />
                  <br />
                  Play Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;
